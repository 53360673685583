<template>
  <b-dropdown
    size="sm"
    variant="link-dark"
    right
    no-caret
    menu-class="custom-dropdown"
    v-if="attachments.length && downloadStyleFile === 'menu'"
    @shown="active = !active"
    @hidden="active = !active"
  >
    <template #button-content>
      <span v-if="!getIsMobile" class="dropdown-button">
        {{ $t("general.materials") }}
        <img
          class="dropdown-arrow"
          :class="{ opened: active }"
          src="@/assets/icons/arrow-navigate.svg"
          alt="arrow icon"
        />
      </span>
      <div
        v-else
        class="btn-player-mobile"
        style="position: relative; bottom: -6px"
      >
        <svg
          width="17"
          height="22"
          viewBox="0 0 17 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.375 1H2.875C2.37772 1 1.90081 1.21071 1.54917 1.58579C1.19754 1.96086 1 2.46957 1 3V19C1 19.5304 1.19754 20.0391 1.54917 20.4142C1.90081 20.7893 2.37772 21 2.875 21H14.125C14.6223 21 15.0992 20.7893 15.4508 20.4142C15.8025 20.0391 16 19.5304 16 19V7M10.375 1L16 7M10.375 1V7H16M12.25 12H4.75M12.25 16H4.75M6.625 8H4.75"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span style="margin-top: 19px" class="font-btn-player-mobile"
          >Materiais</span
        >
      </div>
    </template>
    <b-dropdown-item
      v-for="(item, index) in attachments"
      :key="index"
      @click="download(item, index)"
    >
      <section class="materials-item">
        <section class="type">
          <div :class="`animate-download-icon-${index}`">
            <SvgIcon data-anima="top" v-if="loadItem.filter(i => i.order === index).length > 0 && loadItem.filter(i => i.order === index)[0].check" path="check" />
            <SvgIcon v-if="loadItem.filter(i => i.order === index).length > 0 && loadItem.filter(i => i.order === index)[0].downloading" :path="getIcon('icon-download')" />
            <SvgIcon data-anima="bottom" v-if="loadItem.filter(i => i.order === index).length === 0" :path="getIcon(item.path)" />
          </div>
        </section>
        <div class="materials-item-container">
          <span class="title">{{ item.title }}</span>
          <span v-if="item.size" class="subtitle">{{ item.size }}</span>
          <div :class="`animate-download-progress-container-${index}`" class="download-loading-container">
            <div :class="`animate-download-progress-loader-${index}`" class="animate-download-progress-loader"></div>
          </div>
        </div>
      </section>
    </b-dropdown-item>
  </b-dropdown>
  <div class="list-downloads-container" v-else>
    <b-col class="padding-item" style="cursor: pointer;" cols="12" sm="12" md="6" xl="4"
      v-for="(item, index) in attachments"
      :key="index"
      @click="download(item, index)"
    >
      <section class="material-item-list">
        <section class="type">
          <div :class="`animate-download-icon-${index}`">
            <SvgIcon data-anima="top" v-if="loadItem.filter(i => i.order === index).length > 0 && loadItem.filter(i => i.order === index)[0].check" path="check" />
            <SvgIcon v-if="loadItem.filter(i => i.order === index).length > 0 && loadItem.filter(i => i.order === index)[0].downloading" :path="getIcon('icon-download')" />
            <SvgIcon data-anima="bottom" v-if="loadItem.filter(i => i.order === index).length === 0" :path="getIcon(item.path)" />
          </div>
        </section>
        <div class="materials-item-container">
          <span class="title">{{ item.title }}</span>
          <span v-if="item.size" class="subtitle">{{ item.size }}</span>
          <div :class="`animate-download-progress-container-${index}`" class="download-loading-container">
            <div :class="`animate-download-progress-loader-${index}`" class="animate-download-progress-loader"></div>
          </div>
        </div>
      </section>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// Axios
import axios from "axios";
// Cookies
import Cookies from "js-cookie";
// icons
import SvgIcon from "./SvgIcon.vue";
export default {
  computed: {
    ...mapGetters(["getCurrentLesson", "getIsMobile"]),
    downloadStyleFile() {
      return this.$store.state.metas.metas.download_style_file || "menu";
    },
    attachments() {
      return this.getCurrentLesson?.attachments || [];
    },
  },
  data: () => ({
    active: false,
    materials: [],
    loadItem: [],
  }),
  components: {
    SvgIcon,
  },
  methods: {
    getMainColor() {
      let mainColor = "";
      const element = document.getElementById("club-v2");
      if (element) {
        const style = getComputedStyle(element);
        mainColor = style.getPropertyValue("--maincolor");
      }
      return mainColor;
    },
    getIcon(type) {
      const types = [
        {
          name: "pdf",
          icon: "doc",
        },
        {
          name: "ppt",
          icon: "doc",
        },
        {
          name: "pptx",
          icon: "doc",
        },
        {
          name: "doc",
          icon: "doc",
        },
        {
          name: "docx",
          icon: "doc",
        },
        {
          name: "mp3",
          icon: "headset",
        },
        {
          name: "mp4",
          icon: "headset",
        },
        {
          name: "jpg",
          icon: "img",
        },
        {
          name: "png",
          icon: "img",
        },
        {
          name: "webp",
          icon: "img",
        },
        {
          name: "jpeg",
          icon: "img",
        },
        {
          name: "gif",
          icon: "img",
        },
        {
          name: "xls",
          icon: "excel",
        },
        {
          name: "xlsx",
          icon: "excel",
        },
        {
          name: "zip",
          icon: "doc",
        },
        {
          name: "rar",
          icon: "doc",
        },
        {
          name: "icon-download",
          icon: "icon-download",
        },
      ];

      return types.filter((item) => type.toLowerCase().includes(item.name)).pop()?.icon || "doc";
    },
    initDownloadAnimation(i) {
      const index = i;
      const optionsAnimationIcon = {
        duration: 1000,
        easing: 'ease-out',
        fill: 'forwards',
      };
      const optionsAnimationProgressLoader = {
        duration: 1400,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const optionsAnimationProgressContainer = {
        duration: 300,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const keyframesProgressLoader = [
        { width: '0%'  },
        { width: '100%' }
      ];
      const keyframesProgressContainer = [
        { marginTop: '30px' },
        { marginTop: '10px' }
      ];
      const keyframesIcon = [
        { marginTop: '-70px' },
        { marginTop: '70px' }
      ];
      
      const progressContainer = document.getElementsByClassName(
        `animate-download-progress-container-${index}`
      )[0];
      const progressLoader = document.getElementsByClassName(
        `animate-download-progress-loader-${index}`
      )[0];
      const progressContainerAnimation = progressContainer.animate(keyframesProgressContainer, optionsAnimationProgressContainer);
      progressContainerAnimation.onfinish = () => {
        progressLoader.animate(keyframesProgressLoader, optionsAnimationProgressLoader);
      }
      const icon = document.getElementsByClassName(
        `animate-download-icon-${index}`
      )[0];
      let animeIcon = icon.animate(keyframesIcon, optionsAnimationIcon);

      const recursiveAnimation = (currentIndex) => {
        if (this.loadItem.filter(i => i.order === currentIndex && i.check).length > 0) {
          return;
        }
        if (this.loadItem.filter(i => i.order === currentIndex && i.downloading).length > 0) {
          animeIcon = icon.animate(keyframesIcon, optionsAnimationIcon);
          setTimeout(() => {
            recursiveAnimation(currentIndex);
          }, 1000);
          return;
        }
      };

      animeIcon.onfinish = () => {
        recursiveAnimation(index);
      };
    },
    stopDownloadAnimation(i) {
      const index = i;
      const progressContainer = document.getElementsByClassName(
        `animate-download-progress-container-${index}`
      )[0];
      const progressLoader = document.getElementsByClassName(
        `animate-download-progress-loader-${index}`
      )[0];
      progressLoader.style.width = '100% !important';

      const icon = document.getElementsByClassName(
        `animate-download-icon-${index}`
      )[0];
      const optionsAnimationIcon = {
        duration: 1,
        easing: 'ease-out',
        fill: 'forwards'
      };
      const keyframesIcon = [
        { marginTop: '70px' },
        { marginTop: '0px' },
      ];
      icon.animate(keyframesIcon, optionsAnimationIcon);
        
      setTimeout(() => {
        const optionsAnimationProgressLoader = {
          duration: 1,
          easing: 'ease-out',
          fill: 'forwards'
        };
        const optionsAnimationProgressContainer = {
          duration: 300,
          easing: 'ease-out',
          fill: 'forwards'
        };
        const keyframesProgressLoader = [
          { width: '0%' },
          { width: '100%'  },
        ];
        const keyframesProgressContainer = [
          { marginTop: '10px' },
          { marginTop: '30px' },
        ];
        const progressContainerAnimation = progressContainer.animate(keyframesProgressContainer, optionsAnimationProgressContainer);
        progressContainerAnimation.onfinish = () => {
          progressLoader.animate(keyframesProgressLoader, optionsAnimationProgressLoader);
        }
        let newItems = this.loadItem;
        this.loadItem = newItems.filter((item) => item.order !== index);
      }, 3000);
    },
    async download(item, index) {
      let newItems = this.loadItem;
      newItems.push({ order: index, check: false, downloading: true });
      this.loadItem = newItems;
      this.initDownloadAnimation(index);
      //download dos arquivos
      let params = this.$route.params;
      await axios({
        url:
          process.env.VUE_APP_API_HOST +
          `/course/${params.curso}/module/${params.modulo}/lesson/${params.aula}/attachment/${item.id}/download`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: Cookies.get("auth_greennCourse"),
        },
      }).then((response) => {
        let newItems = this.loadItem;
        newItems = newItems.map((item) => {
          if (item.order === index) {
            item.check = true;
            item.downloading = false;
          }
          return item;
        });
        this.stopDownloadAnimation(index);
        var fileURL = "";
        if (item.mime === "application/pdf") {
          fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
        } else {
          fileURL = window.URL.createObjectURL(new Blob([response.data]));
        }
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", item.title);
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
  },
  async created() {
    await this.$store.dispatch("fetchMetas", ["download_style_file"]);
  },
};
</script>

<style lang="scss" scoped>
.font-btn-player-mobile {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}
.materials-item-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-right: 12px;
  padding-top: 14px;
  height: 60px;
}
.dropdown-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 23px;
  padding-left: 23px;
  padding-top: 13px;
  padding-bottom: 13px;
  background: var(--closes-bg);
  gap: 10px;
  border-radius: 50px;
  color: var(--text-color);
  outline: none;

  .dropdown-arrow {
    transition: ease-in-out 300ms;
    transform: rotate(90deg);

    &.opened {
      transform: rotate(-90deg);
    }
  }
}

.material-item-list {
  background: var(--background2-v2);
  display: flex;
  height: 72px;
  border-radius: 10px;
  padding-left: 17px;
  align-items: center;
  gap: 15px;
  .type {
    background: var(--bordercolor-v2);
    border-radius: 50%;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    display: flex;
    overflow: hidden;
    position: static;
    align-items: center;
    justify-content: center;

    img {
      width: 13px;
      height: 15px;
    }
  }
  .title {
    margin-bottom: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-color);
  }
  .subtitle {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 400;
    line-height: 0px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--fontcolor2-v2);
  }
}
.download-loading-container {
  background: var(--bordercolor-v2);
  border-radius: 2px;
  width: 100%;
  height: 3px;
  margin-top: 30px;
  position: relative;
  overflow: hidden;
}
.animate-download-progress-loader {
  background: var(--maincolor);
  border-radius: 2px;
  width: 0%;
  height: 3px;
  overflow: hidden;
  animation: animate-download-progress 2s infinite;
}
.materials-item {
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  gap: 15px;
  .type {
    background: var(--background2-v2);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 13px;
      height: 15px;
    }
  }
  .title {
    margin-bottom: 5px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--text-color);
  }
  .subtitle {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 400;
    line-height: 0px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--fontcolor2-v2);
  }
}
.list-downloads-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
}
.padding-item {
  padding-left: 0px;
  padding-right: 58px;
  cursor: pointer;
  margin-bottom: 58px;
}
@media screen and (max-width: 767px) {
  .padding-item {
    padding-left: 0px;
    padding-right: 0px;
    cursor: pointer;
    margin-bottom: 12px;
  }
}
</style>