<template>
  <div>
    <svg v-if="path === 'doc'" width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.04352 0.932129H2.95911C2.49805 0.932129 2.05588 1.11528 1.72987 1.4413C1.40386 1.76731 1.2207 2.20948 1.2207 2.67053V16.5778C1.2207 17.0388 1.40386 17.481 1.72987 17.807C2.05588 18.133 2.49805 18.3162 2.95911 18.3162H13.3895C13.8506 18.3162 14.2928 18.133 14.6188 17.807C14.9448 17.481 15.1279 17.0388 15.1279 16.5778V7.01654M9.04352 0.932129L15.1279 7.01654M9.04352 0.932129V7.01654H15.1279" :stroke="getMainColor()" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-else-if="path === 'headset'" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 11.8333V7.5C1 5.77609 1.68482 4.12279 2.90381 2.90381C4.12279 1.68482 5.77609 1 7.5 1C9.22391 1 10.8772 1.68482 12.0962 2.90381C13.3152 4.12279 14 5.77609 14 7.5V11.8333M14 12.5556C14 12.9386 13.8478 13.306 13.5769 13.5769C13.306 13.8478 12.9386 14 12.5556 14H11.8333C11.4502 14 11.0828 13.8478 10.812 13.5769C10.5411 13.306 10.3889 12.9386 10.3889 12.5556V10.3889C10.3889 10.0058 10.5411 9.6384 10.812 9.36751C11.0828 9.09663 11.4502 8.94444 11.8333 8.94444H14V12.5556ZM1 12.5556C1 12.9386 1.15218 13.306 1.42307 13.5769C1.69395 13.8478 2.06135 14 2.44444 14H3.16667C3.54976 14 3.91716 13.8478 4.18804 13.5769C4.45893 13.306 4.61111 12.9386 4.61111 12.5556V10.3889C4.61111 10.0058 4.45893 9.6384 4.18804 9.36751C3.91716 9.09663 3.54976 8.94444 3.16667 8.94444H1V12.5556Z" :stroke="getMainColor()" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-else-if="path === 'img'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="50px" height="50px" fill-rule="nonzero"><g :fill="getMainColor()" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.12,5.12)"><path d="M7,2v46h36v-32.58594l-13.58984,-13.41406zM9,4h19v13h13v29h-32zM30,5.39063l9.73438,9.60938h-9.73437zM33.5,21.33203c-1.04297,0.00391 -1.89062,0.82422 -1.89062,1.83594c0.00391,1.01172 0.84766,1.83203 1.89063,1.83203c1.04297,0 1.88672,-0.82031 1.89063,-1.83203c0,-1.01172 -0.84766,-1.83203 -1.89062,-1.83594zM21.16797,24c-0.33203,-0.01172 -0.64844,0.13672 -0.84375,0.40625l-7.46094,10.08203c-0.22266,0.30469 -0.25781,0.70703 -0.08984,1.04297c0.17188,0.33984 0.51563,0.55078 0.89453,0.55078h14.75781c0.02344,0 0.04688,0 0.07031,0c0.00391,0 0.00391,0 0.00781,0l6.51172,-0.08203c0.38281,-0.00391 0.72656,-0.23047 0.89063,-0.57812c0.16016,-0.34766 0.10938,-0.75391 -0.12891,-1.05078l-6.50781,-7.98437c-0.19141,-0.23828 -0.48437,-0.375 -0.78906,-0.37109c-0.31641,0.00781 -0.60937,0.16016 -0.79297,0.41406l-2.13281,2.93359l-3.61719,-4.95312c-0.18359,-0.24609 -0.46484,-0.39844 -0.76953,-0.41016zM21.125,26.6875l3.62891,4.97266c0.00391,0.00781 0.00781,0.01172 0.01172,0.01563l1.75781,2.40625h-10.87109zM28.53906,28.65625l4.37891,5.37109l-3.91797,0.05078l-2.20312,-3.01562z"></path></g></g>
    </svg>
    <svg v-else-if="path === 'excel'" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0,0,256,256" width="50px" height="50px" fill-rule="nonzero"><g :fill="getMainColor()" fill-rule="nonzero" stroke="none" stroke-width="1" stroke-linecap="butt" stroke-linejoin="miter" stroke-miterlimit="10" stroke-dasharray="" stroke-dashoffset="0" font-family="none" font-weight="none" font-size="none" text-anchor="none" style="mix-blend-mode: normal"><g transform="scale(5.12,5.12)"><path d="M28.875,0c-0.01953,0.00781 -0.04297,0.01953 -0.0625,0.03125l-28,5.3125c-0.47656,0.08984 -0.82031,0.51172 -0.8125,1v37.3125c-0.00781,0.48828 0.33594,0.91016 0.8125,1l28,5.3125c0.28906,0.05469 0.58984,-0.01953 0.82031,-0.20703c0.22656,-0.1875 0.36328,-0.46484 0.36719,-0.76172v-5h17c1.09375,0 2,-0.90625 2,-2v-34c0,-1.09375 -0.90625,-2 -2,-2h-17v-5c0.00391,-0.28906 -0.12109,-0.5625 -0.33594,-0.75391c-0.21484,-0.19141 -0.50391,-0.28125 -0.78906,-0.24609zM28,2.1875v4.34375c-0.13281,0.27734 -0.13281,0.59766 0,0.875v35.40625c-0.02734,0.13281 -0.02734,0.27344 0,0.40625v4.59375l-26,-4.96875v-35.6875zM30,8h17v34h-17v-5h4v-2h-4v-6h4v-2h-4v-5h4v-2h-4v-5h4v-2h-4zM36,13v2h8v-2zM6.6875,15.6875l5.46875,9.34375l-5.96875,9.34375h5l3.25,-6.03125c0.22656,-0.58203 0.375,-1.02734 0.4375,-1.3125h0.03125c0.12891,0.60938 0.25391,1.02344 0.375,1.25l3.25,6.09375h4.96875l-5.75,-9.4375l5.59375,-9.25h-4.6875l-2.96875,5.53125c-0.28516,0.72266 -0.48828,1.29297 -0.59375,1.65625h-0.03125c-0.16406,-0.60937 -0.35156,-1.15234 -0.5625,-1.59375l-2.6875,-5.59375zM36,20v2h8v-2zM36,27v2h8v-2zM36,35v2h8v-2z"></path></g></g></svg>
    <svg v-else-if="path === 'icon-download'" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 1V15M8 15L15 8M8 15L1 8" :stroke="getMainColor()" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <svg v-else-if="path === 'check'" width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 1L6 12L1 7" :stroke="getMainColor()" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    path: {
      type: String,
      required: true
    },
    classSvg: {
      type: String,
      default: ""
    }
  },
  methods: {
    getMainColor() {
      let mainColor = "";
      const element = document.getElementById('club-v2');
      if (element) {
        const style = getComputedStyle(element);
        mainColor = style.getPropertyValue('--maincolor');
      }
      return mainColor;
    },
    getSVG(iconePath) {
      const svg = require(`${iconePath}`);
      return svg;
    }
  }
};
</script>

<style scoped>
svg {
  width: 15px;
}
</style>